import { ROUTING_ACCESSES } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "dev-pages" */
    /* webpackPrefetch: true */
    "./design-page.vue"
  );

export function createDesignRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.PUBLIC],
    },
    path: "/design",
  };
}
