var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "input-multi" },
    [
      _c(_setup.InputBase, {
        attrs: {
          "lookup-options": _setup.unselectedOptions,
          "lookup-text-field": _vm.textField,
          "lookup-value-field": _vm.valueField,
          disabled: _setup.inputDisabled,
        },
        on: { lookup: _setup.handleInputBaseLookup },
        model: {
          value: _setup.inputBaseValue,
          callback: function ($$v) {
            _setup.inputBaseValue = $$v
          },
          expression: "inputBaseValue",
        },
      }),
      _vm._v(" "),
      !_vm.hide
        ? _c(
            _setup.BFormTags,
            _vm._g(
              {
                staticClass: "input-multi-tags",
                attrs: {
                  id: _vm.id,
                  state: _vm.state,
                  value: _setup.selectedOptionsValues,
                  disabled: _vm.disabled,
                  "remove-on-delete": "",
                  "add-on-change": "",
                  "no-outer-focus": "",
                  "tag-variant": "primary",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({
                        tags,
                        disabled: tagsDisabled,
                        removeTag,
                      }) {
                        return [
                          tags.length === 0
                            ? _c("p", { staticClass: "mt-2" }, [
                                _vm._v(
                                  "\n        " +
                                    _vm._s(_vm.placeholder) +
                                    "\n      "
                                ),
                              ])
                            : _c(
                                "ul",
                                {
                                  staticClass:
                                    "list-inline d-inline-block mt-2",
                                },
                                _vm._l(tags, function (tag) {
                                  return _c(
                                    "li",
                                    {
                                      key: tag,
                                      staticClass: "list-inline-item",
                                    },
                                    [
                                      _c(
                                        _setup.BFormTag,
                                        {
                                          attrs: {
                                            title: tag,
                                            variant: "secondary",
                                            pill: "",
                                            disabled:
                                              tagsDisabled ||
                                              _setup.isTagDisabled(tag),
                                          },
                                          on: {
                                            remove: function ($event) {
                                              return removeTag(tag)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(_setup.tagText(tag)) +
                                              "\n          "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1211266895
                ),
              },
              _vm.$listeners
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }