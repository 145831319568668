<template>
  <div
    :id="id"
    class="rich-text-wrapper"
    :class="{ disabled, valid: state === true, invalid: state === false }"
  >
    <div
      v-if="!hide"
      :id="targetId"
    />
    <ItemsCounter :delta="value" />
  </div>
</template>

<script>
import { isEqual } from "@/lib/objects";
import { camelCase } from "@/lib/strings";
import Quill from "quill";
import "quill/dist/quill.snow.css";

import ItemsCounter from "./items-counter.vue";

export default {
  components: { ItemsCounter },
  computed: {
    targetId() {
      return camelCase("quill", this.id);
    },
  },
  data() {
    return {
      quill: null,
    };
  },
  methods: {
    handleContentChange() {
      // quill emite um tamanho de "1" mesmo quando não há texto em função de caracter padrão para documento vazio
      const value = this.quill.getLength() <= 1 ? null : this.quill.getContents();
      this.$emit("input", value);
      // o provedor de validação não tem reagido e engatilhado validações a partir do evento input para este componente. daí emitimos também o evento 'change'.
      this.$emit("change", value);
    },
    initQuill() {
      const target = document.getElementById(this.targetId);
      this.quill = new Quill(target, {
        bounds: target,
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            [{ header: "1" }, { header: "2" }],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            ["link"],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
        readOnly: this.disabled,
        theme: "snow",
      });
      this.updateContents(this.value);
      this.quill.on("text-change", this.handleContentChange);
    },
    updateContents(newContents) {
      const currentContents = this.quill.getContents();
      if (isEqual(newContents, currentContents)) return;
      this.quill.setContents(newContents);
    },
    updateMode(isDisabled) {
      this.quill.enable(!isDisabled);
    },
  },
  mounted() {
    if (this.quill) return;
    this.initQuill();
  },
  name: "InputRichText",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    hide: {
      default: false,
      type: Boolean,
    },
    id: {
      required: true,
      type: String,
    },
    state: {
      default: null,
      type: Boolean,
    },
    value: {
      default: () => ({}),
      type: Object,
    },
  },
  watch: {
    disabled: {
      handler: "updateMode",
    },
    value: {
      handler: "updateContents",
    },
  },
};
</script>

<style scoped>
/* a localização de texto do tema do Quill é (estranhamente) feita por meio de CSS */
.rich-text-wrapper::v-deep .ql-tooltip.ql-editing::before {
  content: "Digite o link:";
}

.rich-text-wrapper::v-deep .ql-tooltip::before {
  content: "Visitar URL:";
}

.rich-text-wrapper::v-deep .ql-tooltip.ql-editing a.ql-action::after {
  content: "Salvar";
}

.rich-text-wrapper::v-deep .ql-tooltip a.ql-action::after {
  content: "Editar";
}

.rich-text-wrapper::v-deep .ql-tooltip a.ql-remove::before {
  content: "Remover";
}

.rich-text-wrapper {
  border: var(--w-border-neutral);
}

.rich-text-wrapper.valid {
  border-color: var(--w-color-success-40);
}

.rich-text-wrapper.invalid {
  border-color: var(--w-color-danger-40);
}

.rich-text-wrapper::v-deep .ql-toolbar {
  border: none;
  background-color: var(--w-color-neutral-20);
}

.rich-text-wrapper::v-deep .ql-container {
  border: none;
  height: var(--w-size-70);
}

.disabled::v-deep .ql-toolbar {
  border-bottom: 0;
  padding: 0;
}

.disabled::v-deep .ql-formats {
  display: none;
}
</style>
