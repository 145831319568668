var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.DialogForm, {
    attrs: {
      "default-payload": _vm.defaultRequester,
      "external-errors": _vm.externalErrors,
      "external-errors-field-map": {
        codigo: "codigoRequisitante",
        "apikey.valor": "apikeyValue",
        "apikey.chave": "apikeyName",
        "oauth2.tokenUrl": "oauth2Url",
        "oauth2.clientId": "oauth2Client",
        "oauth2.clientSecret": "oauth2Secret",
      },
      "show-action-divider": "",
    },
    on: {
      save: function ($event) {
        return _vm.$emit("save", $event)
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "body",
          fn: function ({ payload }) {
            return [
              _c(
                _setup.RowForm,
                [
                  _c(
                    _setup.ColBase,
                    [
                      _c(_setup.ControlSelectSingle, {
                        attrs: {
                          field: "tipoConta",
                          disabled: _vm.isEditMode,
                          options: ["PESSOA", "SISTEMA"],
                          rules: "required",
                          label: "Tipo de Requisitante",
                        },
                        model: {
                          value: payload.tipoConta,
                          callback: function ($$v) {
                            _vm.$set(payload, "tipoConta", $$v)
                          },
                          expression: "payload.tipoConta",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                _setup.RowForm,
                [
                  _c(
                    _setup.ColBase,
                    [
                      _c(_setup.ControlSelectMulti, {
                        attrs: {
                          field: "grupos",
                          options: _setup.groupsAsOptions,
                          "value-field": "id",
                          "text-field": "nome",
                          rows: 3,
                          label: "Grupos",
                        },
                        model: {
                          value: payload.grupos,
                          callback: function ($$v) {
                            _vm.$set(payload, "grupos", $$v)
                          },
                          expression: "payload.grupos",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                _setup.ControlSet,
                { attrs: { legend: "dados da conta" } },
                [
                  _c(_setup.AccountDialog, {
                    ref: "accountDialog",
                    attrs: {
                      field: "requisitante",
                      "is-edit-mode": _vm.isEditMode,
                      "tipo-codigo": payload.tipoCodigo,
                      codigo: payload.codigo,
                      nome: payload.nome,
                      email: payload.email,
                      "email-label": _setup.isRequesterPessoa(payload)
                        ? "E-mail"
                        : "E-mail do sistema",
                      celular: payload.celular,
                      "celular-label": _setup.isRequesterPessoa(payload)
                        ? "Celular"
                        : "Celular de contato",
                    },
                    on: {
                      "update:tipoCodigo": function ($event) {
                        return _vm.$set(payload, "tipoCodigo", $event)
                      },
                      "update:tipo-codigo": function ($event) {
                        return _vm.$set(payload, "tipoCodigo", $event)
                      },
                      "update:codigo": function ($event) {
                        return _vm.$set(payload, "codigo", $event)
                      },
                      "update:nome": function ($event) {
                        return _vm.$set(payload, "nome", $event)
                      },
                      "update:email": function ($event) {
                        return _vm.$set(payload, "email", $event)
                      },
                      "update:celular": function ($event) {
                        return _vm.$set(payload, "celular", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _setup.isRequesterSistema(payload)
                ? [
                    _c(_setup.SpacerBase, { attrs: { vertical: "10" } }),
                    _vm._v(" "),
                    _c(_setup.WebhookFields, {
                      model: {
                        value: payload.webhook,
                        callback: function ($$v) {
                          _vm.$set(payload, "webhook", $$v)
                        },
                        expression: "payload.webhook",
                      },
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.isEditMode
                ? [
                    _c(_setup.SpacerBase, { attrs: { vertical: "10" } }),
                    _vm._v(" "),
                    _c(
                      _setup.RowForm,
                      [
                        _c(
                          _setup.ColBase,
                          [
                            _c(_setup.ControlCheckbox, {
                              attrs: {
                                field: "ativo",
                                placeholder: "Ativar Requisitante",
                                switch: "",
                              },
                              model: {
                                value: payload.ativo,
                                callback: function ($$v) {
                                  _vm.$set(payload, "ativo", $$v)
                                },
                                expression: "payload.ativo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ]
          },
        },
        {
          key: "actions",
          fn: function () {
            return [
              _vm._t("actions", null, {
                clearAccountData: _vm.$refs.accountDialog.reset,
              }),
              _vm._v(" "),
              _c(_setup.ButtonsSaveBack, {
                on: {
                  back: function ($event) {
                    return _vm.$emit("back")
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }